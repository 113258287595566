.landing_page_search_section {
  background-image: url("../../images/Imagefet.png");
  background-position: 45% 0%;
  height: 25rem;
  object-fit: cover;
  margin: auto;
  text-align: center;
  padding-top: 3rem;
  padding-left: 15px;
  padding-right: 15px;
  background-position: 45% 10%;
}

.landing_page_search_section_title {
  font-size: 1.3125rem;
  font-family: "Roboto-Medium";
  padding-left: 8px;
  padding-right: 8px;
}
.landing_page_search_section_paragraphe {
  font-size: 0.9rem;
  font-family: "Roboto-Light";
  padding-bottom: 2.1875rem;
}

.search_input_container {
  position: relative;
  text-align: start;
}
.landing_page_search_section_input {
  padding: 0rem 1.1rem;
  background-color: #fff;
  border-radius: 4px;
  border: 0px;
  height: 55px;
  width: 80%;
}
.landing_page_search_section_input::placeholder {
  color: #284358;
  font-size: 0.8rem;
  font-family: "Roboto-Light";
}
.landing_page_search_section_button {
  background-color: #f4ac2f;
  border: 0px;
  border-radius: 0px 6px 6px 0px;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}
.landing_page_search_section_button_icon {
  color: #fff;
}
.landing_page_show_it_works_section {
  padding: 0rem 1.1rem;
  background-color: #eaeff2;
  padding-bottom: 1.3rem;
  text-align: center;
  margin: auto;
}
.landing_page_show_it_works_section_title {
  font-size: 1.3125rem;
  font-family: "Roboto-Medium";
  color: #284358;
  margin: 0px;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}
.landing_page_show_it_works_section_one_paragraphe {
  color: #284358;
  font-size: 1.1rem;
  font-family: "Roboto-Light";
}
.landing_page_show_it_works_section_two {
  padding: 1.25rem 1.75rem;
  background-color: #f4ac2f;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.landing_page_show_it_works_section_two_paragraphe {
  font-size: 1.125rem;
  margin: 0px;
  font-family: "Roboto-Bold";
  color: #fff;
  text-align: start;
}
.landing_page_feed_backs_section {
  padding: 0rem 1.1rem;
  background-color: #f8f8f8;
  padding-bottom: 1.3rem;
  text-align: center;
  margin: auto;
}
.landing_page_feed_backs_section_paragraphe {
  font-size: 1.3125rem;
  font-family: "Roboto-Medium";
  color: #284358;
  margin: 0px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.carousel .control-dots .dot {
  border: 1px solid #f4ac2f;
  box-shadow: none;
  opacity: 1;
  background-color: transparent;
}
.carousel .control-dots .dot.selected {
  border: 1px solid #f4ac2f;
  background-color: #f4ac2f;
}
.carousel_container {
  background-color: #fff;
  border-radius: 11px 11px 52px 11px;
  padding: 1.6875rem;
  margin-bottom: 50px !important;
  position: relative;
  width: 90%;
  margin: auto;
}
.carousel_container_paragraphe {
  color: #284358;
  font-size: 0.875rem;
  font-family: "Roboto-Light";
  position: relative;
  text-align: start;
  margin-top: 0px;
}
.carousel-status {
  display: none;
}
.carousel_container_paragraphe::after {
  position: absolute;
  content: " ";
  height: 1px;
  width: 50%;
  background-color: #f4ac2f;
  bottom: -10px;
  left: 0;
}
.carousel_container_paragraphe_title {
  position: absolute;
  right: -15px;
  bottom: -44px;
  color: #f4ac2f;
  font-size: 5rem;
  margin: 0px;
  font-family: "rockweb";
}
.carousel_container_paragraphe_name {
  color: #284358;
  font-size: 0.875rem;
  font-family: "Roboto-Light";
  text-align: start;
  margin-bottom: 0px;
}
.carousel_container_paragraphe_name span {
  font-family: "Roboto-Bold";
}
.carousel .thumbs-wrapper {
  display: none;
}
.landing_page_pro_section {
  padding: 2rem 1.1rem;
  background-color: #fff;
  padding-bottom: 1.3rem;
  text-align: center;
  margin: auto;
}
.landing_page_pro_section_title {
  font-size: 1.3rem;
  font-family: "Roboto-Bold";
  color: #284358;
}
.landing_page_pro_section_paaragraphe {
  font-size: 1.1rem;
  font-family: "Roboto-light";
  color: #284358;
}
.landing_page_pro_section_button {
  font-size: 1rem;
  font-family: "Roboto-Bold";
  color: #fff;
  background-color: #f4ac2f;
  border-radius: 6px;
  border: 0px;
  padding: 1rem 0rem;
  width: 100%;
}
