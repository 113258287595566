.ErrorPage_container {
  padding: 0rem 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: fixed;
}
.ErrorPage_title {
  color: #284358;
  font-size: 1.4rem;
  font-family: "Roboto-Bold";
}
.ErrorPage_paragraphe {
  font-family: "Roboto-Regular";
  font-size: 0.8rem;
  color: #284358;
}
.ErrorPage_button {
  border: 0px;
  border-radius: 6px;
  background-color: #f4ac2f;
  box-shadow: 0px 3px 6px #00000008;
  color: #fff;
  font-size: 1rem;
  font-family: "Roboto-Bold";
  width: 100%;
  height: 3.4rem;
}
