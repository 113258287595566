.Footer {
  padding: 2rem 1.1rem;
  background-color: #223444;
  padding-bottom: 100px;
}
.Footer_logo {
  display: flex;
  margin: auto;
}
.Footer_paragraphe {
  font-family: "Roboto-Regular";
  font-size: 0.88rem;
  /* text-align: center; */
  color: #bbc8d3;
  text-align: justify;
}
.Footer_links_container {
  display: flex;
  flex-wrap: wrap;
}
.Footer_links_container_first_section_title {
  color: #ffffff;
  font-size: 1rem;
  font-family: "Roboto-Medium";
}
.Footer_links_container_first_section_paragraphe {
  font-family: "Roboto-Regular";
  font-size: 0.88rem;
  color: #bbc8d3;
  text-decoration: none;
}
.Footer_links_container_first_section {
  width: 50%;
}
.contact_section {
  display: flex;
  align-items: center;
}
.contact_section img {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.Footer_icons_container {
  margin-top: 5rem;
  position: relative;
  display: flex;
  justify-content: center;
}
.Footer_icons_container::before {
  position: absolute;
  content: "";
  height: 0.2px;
  width: 100%;
  background-color: #ecf8fa;
  top: -2.5rem;
  left: 0px;
  right: 0px;
  margin: auto;
}
.Footer_offered_by_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.Footer_offered_by_text {
  color: #bbc8d3;
  font-size: 0.9rem;
  font-family: "Roboto-Medium";
  margin: 0px;
}
.Footer_offered_by_image {
  margin-left: 10px;
}
.Footer_offered_by_text_brand {
  color: #bbc8d32f;
  margin: 0px;
  font-size: 0.9rem;
  font-family: "Roboto-Medium";
}
