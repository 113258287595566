.UserProfileConsulted_doc_button_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.UserProfileConsulted_doc_button_consult {
  width: 50%;
  height: 3.43rem;
  border-top: 1px solid #f0f4f7;
  border-right: 1px solid #f0f4f7;
  color: #00b2d8;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  border-bottom: 0px;
  border-left: 0px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UserProfileConsulted_doc_button_add_to_favirot {
  width: 50%;
  height: 3.43rem;
  border-top: 1px solid #f0f4f7;
  color: #acbbc6;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
