.UserProfileIfo_header {
  padding: 0rem 1.1rem;
  box-shadow: 0px 3px 6px #00000008;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.UserProfileIfo_header_title {
  font-family: "Roboto-Bold";
  font-size: 1.1rem;
  color: #284358;
  margin-left: 1rem;
}
.UserProfileIfo_header_lil_container {
  display: flex;
  align-items: center;
}
.UserProfileIfo_header_update {
  font-family: "Roboto-Regular";
  color: #f4ac2f;
  font-size: 0.75rem;
  text-decoration: underline;
}
.UserProfileIfo_content {
  padding: 0rem 1.1rem;
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
}
.UserProfileIfo_content::after {
  position: absolute;
  background-color: #f0f4f7;
  height: 1px;
  width: 100%;
  right: 0px;
  left: 0px;
  bottom: -20px;
  content: " ";
}
.UserProfileIfo_content_title {
  font-family: "Roboto-Regular";
  font-size: 0.7rem;
  color: #284358;
  margin-bottom: 0px;
}
.UserProfileIfo_content_text {
  font-family: "Roboto-Bold";
  font-size: 1rem;
  color: #284358;
  margin-top: 4px;
}
