.StickyFooter {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  padding: 1rem 0rem 0rem;
  border-radius: 10px 10px 0px 0px;
  justify-content: space-between;
}
.StickyFooter_icons {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.StickyFooter_icons_paragrapge {
  font-size: 0.68rem;
  font-family: "Roboto-Medium";
  color: #b4bec6;
}
.StickyFooter_icons svg {
  color: #b4bec6;
}

.StickyFooter_selected {
  color: #f4ac2f !important;
}
