.ProductPage_carousel_product_added_notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProductPage_carousel_product_added_notification_icon {
  font-size: 35px;
  color: #1bbaac;
  margin-right: 6px;
}
.ProductPage_carousel_product_added_notification_text {
  font-family: "Poppins";
  font-size: 15px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 0px;
}
.ProductPage_carousel_product_added_notification_icon_close {
  font-size: 35px;
  color: #535353;
}

.ProductPage_carousel_product_added_notification {
  width: 80%;
  padding: 22px 16px;
  background-color: #ecf9f8;
  box-shadow: #0000000b 0px 0px 13px;
  border-radius: 10px;
  margin: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 80vh;
  z-index: 100000;
}

.ProductPage_carousel_product_added_notification_danger_icon {
  font-size: 35px;
  color: #ba1b1b;
  margin-right: 6px;
}

.ProductPage_carousel_product_added_notification_danger {
  width: 80%;
  padding: 22px 16px;
  background-color: #f9ecec;
  box-shadow: #0000000b 0px 0px 13px;
  border-radius: 10px;
  margin: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 80vh;
  z-index: 100000;
}

.ProductPage_carousel_product_added_notification_warning_icon {
  font-size: 35px;
  color: #ba9a1b;
  margin-right: 6px;
}

.ProductPage_carousel_product_added_notification_warning {
  width: 80%;
  padding: 22px 16px;
  background-color: #f9f7ec;
  box-shadow: #0000000b 0px 0px 13px;
  border-radius: 10px;
  margin: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 80vh;
  z-index: 100000;
  align-items: center;
  justify-content: space-between;
}

.FlashMessage__container {
  position: relative;
}
