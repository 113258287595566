@font-face {
  font-family: "Roboto-Bold";
  src: url("./fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "rockweb";
  src: url("./fonts/rockweb.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto-Regular.ttf");
}
