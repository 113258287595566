.DoctorView {
  background-color: #f5f9fc;
}
.DoctorView_header_container {
  height: 244px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #00b2d8;
  border-radius: 0px 0px 20px 20px;
  padding: 0rem 1.1rem;
  box-shadow: 0px 3px 6px #00000008;
  background-image: url("../../images/GroupeMasques.png");
}
.DoctorView_doc_name {
  color: #ffffff;
  font-size: 1.1rem;
  font-family: "Roboto-Bold";
  margin: 0;
  margin-top: 5px;
}
.DoctorView_header_content {
  text-align: center;
  margin-top: 3rem;
}
.DoctorView_header_container > svg {
  margin-top: 1.3rem;
}
.DoctorView_doc_specialty {
  color: #ffffff;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  margin: 0;
  margin-top: 5px;
}
.DoctorView_doc_adress {
  color: #ffffff;
  font-size: 0.7rem;
  font-family: "Roboto-Light";
  margin: 0px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.DoctorView_doc_adress svg {
  margin-left: 10px;
}
.DoctorView_scroll_y {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 1.09rem;
  padding-bottom: 1.4rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  overflow-y: scroll;
}
.DoctorView_scroll_y_content,
.DoctorView_scroll_y_content_active {
  margin-top: 25px;
  position: relative;
}
.DoctorView_scroll_y_content_text_active {
  color: #284358;
  font-size: 1rem;
  font-family: "Roboto-Medium";
  margin: 0;
}
.DoctorView_scroll_y_content_text {
  color: #bbc8d3;
  font-size: 1rem;
  font-family: "Roboto-Medium";
  margin: 0;
}
.DoctorView_scroll_y_content_active::after {
  position: absolute;
  background-color: #f4ac2f;
  height: 3px;
  width: 100%;
  bottom: -20px;
  right: 0px;
  left: 0px;
  content: " ";
}
.DoctorView_drop_down_header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
.DoctorView_drop_down_header_lil_groupe {
  display: flex;
  align-items: center;
}
.DoctorView_drop_down_text {
  margin: 0px;
  color: #284358;
  font-size: 0.9rem;
  font-family: "Roboto-Medium";
  margin-left: 7px;
}
.DoctorView_drop_down_container {
  padding: 0rem 1.1rem;
  padding-top: 5px;
  background-color: #ffffff;
}
.PresentationOfTheFirm_text {
  font-size: 0.7rem;
  font-family: "Roboto-Light";
  color: #284358;
}
.PresentationOfTheFirm_image_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.PresentationOfTheFirm_image {
  width: 30%;
  margin-top: 5%;
  object-fit: cover;
}
.Training {
  display: flex;
  margin-bottom: 0.9rem;
}
.Training_year {
  color: #00b2d8;
  font-size: 0.7rem;
  font-family: "Roboto-Medium";
  margin: 0px;
  margin-right: 10px;
}
.Training_content {
  font-size: 0.6rem;
  font-family: "Roboto-Light";
  color: #284358;
  margin: 0px;
}
.SpokenLanguages {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.SpokenLanguages_image {
  width: 8%;
  margin-right: 10px;
}
.CareAndProcedures_container {
  display: flex;
  flex-wrap: wrap;
}
.CareAndProcedures_button {
  color: #00b2d8;
  background-color: #ffffff;
  border: 1px solid #00b2d8;
  padding: 10px;
  border-radius: 6px;
  margin: 5px;
}
.PaymentMethodsAccepted {
  width: auto;
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.PaymentMethodsAccepted_text {
  color: #284358;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  margin: 0px;
  margin-left: 12px;
}
.LocationCentent {
  background-color: #ffffff;
  padding: 0rem 1.1rem;
}
.locationCentent_title {
  color: #00b2d8;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  margin: 0px;
}
.locationCentent_text {
  color: #284358;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  margin: 0px;
}
.WorkTimetable_title {
  color: #284358;
  font-size: 0.8rem;
  font-family: "Roboto-Medium";
  margin: 0px;
  margin-bottom: 17px;
}
.WorkTimetable_container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
}
.WorkTimetable_container::after {
  position: absolute;
  background-color: #f0f4f7;
  content: " ";
  height: 1px;
  width: 100%;
  right: 0px;
  left: 0px;
  bottom: -15px;
}
.WorkTimetable_big_container {
  background-color: #ffffff;
  padding: 0rem 1.1rem;
  padding-top: 1rem;
}
.WorkTimetable_phoneNumber {
  font-family: "Roboto-Medium";
  color: #284358;
  font-size: 0.75rem;
}
.WorkTimetable_content {
  font-family: "Roboto-Medium";
  color: #284358;
  font-size: 0.65rem;
}
.WorkTimetable_phoneNumber span {
  font-family: "Roboto-Medium";
  color: #f4ac2f;
  font-size: 0.75rem;
  text-decoration: underline;
  margin-left: 6px;
}
.tariff_display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tariff_display_what {
  font-family: "Roboto-Medium";
  color: #284358;
  font-size: 0.75rem;
}
.tariff_display_number {
  font-family: "Roboto-Bold";
  color: #284358;
  font-size: 0.75rem;
}
.DoctorView_header_image_container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.DoctorView_header_image_container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dropDown {
  overflow: hidden;
  height: 0px;
  transition: height ease 0.5s;
}
.dropDown_active {
  height: fit-content;
}
.DoctorsList_container {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 1rem;
}
.specialy_list {
  padding: 0.3rem 1.1rem;
  display: flex;
  overflow-x: scroll;
  margin: 0.5rem 0px;
  position: relative;
}
.specialy_list::-webkit-scrollbar {
  display: none;
}
.specialy_name {
  border-radius: 20px;
  border: 1px solid #ccdae5;
  font-size: 0.7rem;
  color: #ccdae5;
  font-family: "Roboto-Medium";
  padding: 0rem 1rem;
  margin-left: 10px;
  white-space: nowrap;
}
.specialy_name_active {
  border-radius: 20px;
  border: 1px solid #00b2d8;
  font-size: 0.7rem;
  background-color: #00b2d8;
  color: #ffffff;
  font-family: "Roboto-Medium";
  padding: 0rem 1rem;
  margin-left: 10px;
  white-space: nowrap;
  box-shadow: 0px 5px 10px #00000008;
  position: relative;
}

.healthCare_establishment_data {
  background-color: #f5f9fc;
  padding: 16px;
  border-radius: 6px;
  text-align: start;
  position: relative;
}

.healthCare_establishment_title {
  font-family: "Roboto-Medium";
  color: #284358;
  font-size: 0.75rem;
}

.healthCare_establishment_link {
  font-family: "Roboto-Light";
  color: #284358;
  font-size: 0.75rem;
}

.specialy_name_active::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f5f9fc;
  bottom: -14px;
  position: absolute;
  left: 22px;
}
