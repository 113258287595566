.UserProfile_logout {
  font-family: "Roboto-Medium";
  color: #facc7d;
  font-size: 0.75rem;
  text-decoration: underline;
  margin-left: 6px;
}
.user_links {
  background-color: #ffffff;
  padding-top: 1rem;
}
.user_link {
  position: relative;
  padding: 0rem 1.1rem;
  margin-top: 20px;
  margin-bottom: 40px;
}
.user_link::after {
  position: absolute;
  background-color: #f0f4f7;
  height: 1px;
  width: 100%;
  right: 0px;
  left: 0px;
  bottom: -14px;
  content: " ";
}
