.SearchPage {
  background: #fff;
  position: relative;
}
.SearchPage_reach_input_container {
  padding: 0rem 1.1rem;
  position: relative;
  box-shadow: 0px 3px 6px #00000008;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: static;
  right: 0;
  left: 0;
  top: 0;
}
.search_input {
  border: none;
  width: 80%;
}
.search_input:focus-visible {
  border-color: white;
  outline-color: white;
}
.search_clear_icon_container {
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecf1f2;
}
.search_result {
  padding: 0rem 1.1rem;
  position: fixed;
  overflow: scroll;
  height: 100%;
  width: -webkit-fill-available;
}
.NoResultSearch_paragraphe {
  font-family: "Roboto-Light";
  color: #b9bfc4;
  font-size: 1.1rem;
  text-align: center;
}
.NoResultSearch {
  margin: auto;
  text-align: center;
}
.DoctorsOffice {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;
}
.DoctorsOffice::after {
  position: absolute;
  content: " ";
  height: 1px;
  width: 109%;
  right: 0;
  left: -16px;
  bottom: -20px;
  background-color: #f0f4f7;
}
.DoctorsOffice_content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.9rem;
}
.doctors_name {
  font-family: "Roboto-Medium";
  font-size: 0.9rem;
  color: #00b2d8;
  margin: 0px;
  margin-bottom: 0.4rem;
}
.doctors_specialty {
  font-family: "Roboto-Medium";
  font-size: 0.7rem;
  color: #284358;
  margin: 0px;
  margin-bottom: 0.4rem;
}
.doctors_address {
  display: flex;
  align-items: center;
  font-family: "Roboto-Light";
  font-size: 0.6rem;
  color: #284358;
  margin: 0px;
}
.doctors_address svg {
  margin-right: 5px;
}
.LocalisationButton {
  background-color: #ecf8fa;
  border-radius: 6px;
  width: 100%;
  padding: 0.8rem 0rem;
  font-family: "Roboto-Medium";
  font-size: 0.7rem;
  color: #00b2d8;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  margin-top: 20px;
}
.SearchSuggestionsText_container {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
}
.SearchSuggestionsText_container::after {
  position: absolute;
  content: " ";
  height: 1px;
  width: 109%;
  right: 0;
  left: -16px;
  bottom: -20px;
  background-color: #f0f4f7;
}
.YourHighlightClass {
  font-family: "Roboto-Medium";
  color: #00b2d8;
  background-color: transparent;
}
