.header_container {
  max-height: 3.75rem;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
  padding-bottom: 7px;
  padding-top: 7px;
  box-shadow: 0px 3px 6px #00000008;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_brande_logo {
  max-width: 120px;
  margin-left: 22px;
  aspect-ratio: 100 / 21;
  object-fit: contain;
}
.header_secend_container_for_first_icon {
  height: 2.1rem;
  width: 2.1rem;
  background-color: #ecf1f2;
  border-radius: 50%;
  border-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_secend_container_for_secend_icon {
  height: 2.1rem;
  width: 2.1rem;
  background-color: #f4ac2f;
  border-radius: 50%;
  border-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.header_icons_conatainer {
  display: flex;
  align-items: center;
}
.header_first_container {
  width: 8rem;
}
.HeaderBurger {
  max-width: -webkit-fill-available;
}
