.SearchResultsPage {
  background-color: #f5f9fc;
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: scroll;
  padding-bottom: 100px;
}
.search_result_header_container {
  background-color: #00b2d8;
  border-radius: 0px 0px 20px 20px;
  padding: 0rem 1.1rem;
  box-shadow: 0px 3px 6px #00000008;
}
.search_result_header,
.search_result_header_copy {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.search_result_header::after {
  position: absolute;
  content: " ";
  background-color: #ffffff;
  height: 1px;
  width: 100%;
  right: 0px;
  left: 0px;
  bottom: -1px;
}
.search_result_header_paragraphe_light {
  color: #ffffff;
  font-size: 0.9rem;
  font-family: "Roboto-Light";
  margin: 0;
  margin-left: 10px;
}
.search_result_header_paragraphe {
  color: #ffffff;
  font-size: 0.9rem;
  font-family: "Roboto-Medium";
  margin: 0;
  margin-left: 10px;
}
.NoResultSearch_paragraphe_light {
  color: #284358;
  font-size: 0.8rem;
  font-family: "Roboto-Light";
  margin: 0;
}
.NoResultSearch_paragraphe_strong {
  color: #284358;
  font-size: 1.3rem;
  font-family: "Roboto-Bold";
  margin: 0;
  margin-bottom: 10px;
}
.FilterAndResult_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1.1rem;
  margin-top: 10px;
  margin-bottom: 20px;
}
.FilterAndResult_number_paragraphe {
  color: #284358;
  font-size: 0.7rem;
  font-family: "Roboto-Light";
  margin: 0;
}
.FilterAndResult_number_paragraphe strong {
  margin-right: 7px;
}
.FilterAndResult_filter_title {
  color: #00b2d8;
  font-size: 0.9rem;
  font-family: "Roboto-Medium";
  margin: 0;
  margin-left: 10px;
}
.FilterAndResult {
  display: flex;
  align-items: center;
}
.FilterAndResult_icon_container {
  position: relative;
}
.FilterAndResult_icon_container::after {
  position: absolute;
  background-color: #f4ac2f;
  height: 6px;
  width: 6px;
  top: 3px;
  left: 74%;
  border-radius: 50%;
  content: " ";
}
.DoctorsOfficeSearchResult {
  background-color: #ffffff;
  padding: 1rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  margin-top: 10px;
}
.DoctorsOfficeSearchResult_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00b2d8;
  font-size: 0.9rem;
  font-family: "Roboto-Medium";
  border-radius: 6px;
  border: 1px solid #00b2d8;
  background-color: transparent;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: 100%;
}
.DoctorsOfficeSearchResult_button svg {
  margin-right: 5px;
}
.DoctorsOfficeSearchResult_container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
