.about_horaire {
  display: flex;
  justify-content: space-between;
}
.about_horaire_bold {
  display: flex;
  justify-content: space-between;
}

/* .about_horaire_bold .day p {
  font-family: "Roboto-Light";
  color: #284358;
  font-size: 14px;
} */

.day {
  width: 33%;
}

.day p {
  font-family: "Roboto-Medium";
  color: #284358;
  font-size: 12px;
  margin: 3px;
}
.day_time p {
  font-family: "Roboto-Light";
  color: #284358;
  font-size: 12px;
  margin: 3px;
}
.day_time {
  width: 22%;
  text-align: center;
}
